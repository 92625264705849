<script lang="ts">
  import { onMount } from "svelte";
  import ReviewsIO from "@images/reviews_logo.svg";
  import StarFilled from "~icons/ant-design/star-filled";
  import Help from "~icons/tabler/help-circle-filled";
  import * as HoverCard from "@components/ux/hover-card";
  import {
    levelsDetails,
    serverNames,
    levels,
    getServerPrices,
    type Region,
    type Level,
    accountsPerSubscription
  } from "@frontend/games/lol/account_info";

  import { addCartItem, isCartClosed, discountPercentage } from "@stores/cart";
  import { client } from "@helpers/trpc/trpc-client";
  import { typedEntries } from "@helpers/typed-entries";
  import SkinSearchDropdown from "@components/skins/SkinSearchDropdown.svelte";
  import Badge from "@components/ux/badge.svelte";
  import Typography from "@components/ux/typography/typography.svelte";
  import AccountsBadges from "@components/accounts/badges.svelte";

  import { Image } from "@components/ux/image";
  export let region: Region = "euw";
  export let level: Level = "30";

  let selectedRegion: Region = region;
  let selectedLevel: Level | null = level;
  let isSubscription = false;
  let skins: string[] = [];
  let stock: Record<string, number> = {};
  let selectedSkin: string | null = null;
  let skinEnabled = false;

  let regionPrices = getServerPrices(selectedRegion, isSubscription);

  $: regionPrices = getServerPrices(selectedRegion, isSubscription);

  let addToCartDisabled = false;

  $: addToCartDisabled =
    !selectedLevel ||
    !selectedRegion ||
    (selectedSkin !== null && !skinEnabled);

  $: if (selectedLevel && !stock[`${selectedRegion}-${selectedLevel}`]) {
    selectedLevel = null;
  }

  onMount(async () => {
    [skins, stock] = await Promise.all([
      client.accounts.skinNames.query(),
      client.accounts.accountStock.query()
    ]);
    selectedRegion = region;
    selectedLevel = level;
  });

  const getPriceAfterDiscount = (price: number) => {
    return +(price * (1 - $discountPercentage)).toFixed(2);
  };

  const getOriginalPrice = (level: Level) => {
    const price = regionPrices.prices[level];

    return (price + (selectedSkin ? regionPrices.skinPrices[level] : 0)) / 100;
  };

  let priceDisplay = "";

  $: if (!addToCartDisabled && selectedLevel) {
    const price = regionPrices.prices[selectedLevel];

    priceDisplay = `${regionPrices.currencyCode}${getPriceAfterDiscount(
      price / 100
    )}`;

    if (selectedSkin) {
      priceDisplay += ` + ${regionPrices.currencyCode}${getPriceAfterDiscount(
        regionPrices.skinPrices[selectedLevel] / 100
      )} Skin`;
    }
  }

  const addSelectedItemToCart = () => {
    if (!selectedLevel) return;

    $isCartClosed = false;

    const levelOriginalPrice = getOriginalPrice(selectedLevel);
    addCartItem({
      type: "account",
      data: {
        region: selectedRegion,
        level: selectedLevel,
        selected_skin: selectedSkin as string
      },
      subscription: isSubscription,
      quantity: 1,
      original_price: levelOriginalPrice,
      price: getPriceAfterDiscount(levelOriginalPrice),
      currency: regionPrices.currencyCode
    });
  };
</script>

<div
  class="container grid gap-4 rounded-[48px] bg-zinc-900 px-10 py-8 max-lg:mt-10 lg:px-20 lg:py-14">
  <div class="flex flex-col gap-2">
    <h2 class="text-4xl font-extrabold lg:text-5xl">
      Buy a <b class="text-gradient-colorful">League of Legends Account</b> now!
    </h2>
    <h3 class="text-xl font-light text-theme-grey lg:text-2xl">
      All our <b>League of Legends accounts</b> come with extra features and benefits,
      for free!
    </h3>
  </div>
  <p class="text-xl uppercase tracking-wider text-white">
    CHOOSE YOUR <span class="text-gradient-colorful">REGION</span>
  </p>
  <div class="grid grid-cols-2 gap-4 lg:grid-cols-4">
    {#each typedEntries(serverNames) as [server, name] (server)}
      <div
        aria-checked={selectedRegion === server}
        class="aria-checked:bg-gradient-colorful h-full rounded-xl bg-clip-padding p-[2px] aria-checked:animate-background">
        <input
          name="server"
          type="radio"
          id={server}
          value={server}
          class="peer hidden"
          bind:group={selectedRegion} />
        <label
          for={server}
          class="relative flex min-h-full w-full cursor-pointer flex-col items-start justify-start rounded-xl border-2 border-neutral-700 bg-zinc-900 p-5 transition-all hover:border-zinc-500 peer-checked:border-transparent">
          <h4 class="text-lg font-medium text-white lg:text-xl">
            {server.toUpperCase()}
          </h4>
          <p class="text-xs">{name}</p>
        </label>
      </div>
    {/each}
  </div>

  <p class="text-xl uppercase tracking-wider text-white">
    CHOOSE YOUR <span class="text-gradient-colorful">PACKAGE</span>
  </p>
  <div class="flex gap-5">
    <div aria-checked={!isSubscription}>
      <input
        name="subscription"
        type="radio"
        id="false"
        value={false}
        bind:group={isSubscription}
        class="peer hidden" />
      <label
        for="false"
        class="relative flex h-full cursor-pointer items-center rounded-xl border-2 border-neutral-700 p-4 font-semibold text-white transition-all hover:border-zinc-500 peer-checked:bg-white peer-checked:text-black"
        >Single</label>
    </div>

    <div>
      <input
        name="subscription"
        type="radio"
        id="true"
        value={true}
        class="peer hidden"
        bind:group={isSubscription} />
      <label
        for="true"
        class="text relative flex cursor-pointer items-center gap-4 rounded-xl border-2 border-neutral-700 px-3 py-4 font-semibold text-white transition-all hover:border-zinc-500 peer-checked:bg-white peer-checked:text-black"
        ><Badge class="bg-gradient-colorful">Save 60%</Badge>
        <span>Subscription</span>

        <HoverCard.Root>
          <HoverCard.Trigger class="hover:cursor-help">
            <Help class="h-6 w-6" />
          </HoverCard.Trigger>
          <HoverCard.Content class="w-80">
            <div class="flex flex-col gap-1">
              <Typography>
                Save more than <b>60%</b> on Subscribing
              </Typography>

              <Typography variant="small" color="muted">
                Cancel <span class="font-semibold text-white">Anytime</span>, No
                Questions Asked!
              </Typography>
              <Typography variant="small" color="muted">
                Claim an Account every <span class="font-semibold text-white"
                  >{30 / accountsPerSubscription}</span> days
              </Typography>
            </div>
          </HoverCard.Content>
        </HoverCard.Root>
      </label>
    </div>
  </div>
  <div class="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4">
    {#each levels as level}
      {@const levelDetails = levelsDetails[level]}
      {@const levelStock = stock[`${selectedRegion}-${level}`]}
      <div
        aria-checked={selectedLevel === level}
        class="aria-checked:bg-gradient-colorful h-full flex-1 rounded-xl bg-clip-padding p-[2px] aria-checked:animate-background">
        <input
          name="level"
          type="radio"
          id={level}
          value={level}
          class="peer hidden"
          bind:group={selectedLevel}
          disabled={!levelStock} />
        <label
          for={level}
          class="relative flex min-h-full w-full cursor-pointer flex-col items-start justify-start rounded-xl border-2 border-neutral-700 bg-zinc-900 p-6 transition-all hover:border-zinc-500 peer-checked:border-transparent peer-disabled:opacity-75 peer-disabled:hover:cursor-not-allowed">
          <h4 class="text-lg font-medium text-white lg:text-2xl">
            {#if isSubscription}
              Up to {accountsPerSubscription} Level {level} Accounts
            {:else}
              Level {level}
            {/if}
          </h4>

          <div class="my-5 flex w-full flex-wrap items-center justify-between">
            <p class="text-xl font-semibold text-white">
              {`${regionPrices.currencyCode}${getPriceAfterDiscount(
                regionPrices.prices[level] / 100
              )}`}
              {#if isSubscription}<span class=" text-sm text-zinc-500"
                  >/Month</span
                >{/if}
            </p>
            <del class="font-medium text-theme-grey">
              {`${regionPrices.currencyCode}${
                regionPrices.originalPrices[level] / 100
              }`}
            </del>
          </div>

          <AccountsBadges {isSubscription} {level} />

          <p
            class={`text-red-700 ${
              levelStock && levelStock > 5 ? "hidden" : "mt-5"
            }`}>
            {!levelStock ? "Sold out!" : `Only ${levelStock} left!`}
          </p>
        </label>
      </div>
    {/each}
  </div>

  {#if selectedLevel && selectedRegion}
    <div class="z-10 mb-[72px] mt-[24px]">
      <SkinSearchDropdown
        {skins}
        {selectedLevel}
        {selectedRegion}
        bind:selectedOption={selectedSkin}
        bind:skinEligible={skinEnabled} />
    </div>
  {/if}

  <div
    class="flex flex-wrap items-center justify-between gap-6 md:flex-nowrap md:p-6">
    <div class="text-2xl">
      Total Price: <span class="text-xl font-semibold text-gray-50 md:text-3xl"
        >{priceDisplay}</span>
    </div>
    <button
      disabled={addToCartDisabled}
      on:click={addSelectedItemToCart}
      data-tooltip-target="checkout-disabled-tooltip"
      class="enabled:hover:bg-gradient-colorful relative basis-[550px] rounded-full bg-zinc-50 px-12 py-5 text-xl font-semibold text-black transition-colors duration-300 enabled:hover:animate-background enabled:hover:text-zinc-50 disabled:cursor-not-allowed disabled:opacity-50 2xl:text-xl">
      {#if addToCartDisabled}
        Please select a valid option
      {:else}
        <div
          class="bg-gradient-colorful absolute -inset-0.5 -z-10 animate-background rounded-full opacity-80 blur-xl" />
        Add to Cart
      {/if}
    </button>
  </div>
</div>
