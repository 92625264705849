export const serverNames = {
  euw: "Europe West",
  na: "North America",
  eune: "Europe Nordic & East",
  tr: "Turkey",
  oce: "Oceania",
  br: "Brazil",
  lan: "Latin America North",
  las: "Latin America South"
} as const;

export const regions = [
  "euw",
  "na",
  "eune",
  "tr",
  "oce",
  "br",
  "lan",
  "las"
] as const;

export const levels = ["30", "40", "45", "60"] as const;
export type Region = keyof typeof serverNames;
export type Level = (typeof levels)[number];

export const levelsDetails: Record<
  Level,
  {
    estimated: string;
    pure: string;
    loot: string;
  }
> = {
  "30": { estimated: "50,000", pure: "15k", loot: "35k" },
  "40": { estimated: "60,000", pure: "20k", loot: "40k" },
  "45": { estimated: "70,000", pure: "25k", loot: "45k" },
  "60": { estimated: "100,000", pure: "30k", loot: "70k" }
} as const;

export const isLevelValid = (level: string) => {
  return levels.includes(level as Level);
};

export const isRegionValid = (region: string) => {
  return regions.includes(region as Region);
};

export const getProductTitle = (
  region: Region,
  level: Level,
  isSubscription = false
) => {
  if (!levelsDetails[level]) return "";

  if (isSubscription)
    return `Up to ${accountsPerSubscription} ${region.toUpperCase()} Level ${level} Smurf Accounts Subscription`;
  //Like EUW Level 30 Smurf Account
  return `${region.toUpperCase()} Level ${level} Smurf Account`;
};

export const getProductDescription = (region: Region, level: Level) => {
  if (!levelsDetails[level]) return "";

  return `Buy an unranked Level ${level} LoL account in the ${region.toUpperCase()} region with about ${
    levelsDetails[level].estimated
  } BE (${levelsDetails[level].pure} Pure ${
    levelsDetails[level].loot
  } Loot) and honor level 2. You're able to change the email to yours!`;
};

type Prices = {
  currency: "eur" | "usd";
  currencyCode: string;
  prices: Record<(typeof levels)[number], number>;
  originalPrices: Record<(typeof levels)[number], number>;
  skinPrices: Record<(typeof levels)[number], number>;
};

export const accountProductPrices: Record<"base" | "na" | "oce", Prices> = {
  base: {
    currency: "eur",
    currencyCode: "€",
    prices: { 30: 999, 40: 1499, 45: 2099, 60: 3099 },
    originalPrices: { 30: 1299, 40: 2099, 45: 2599, 60: 3999 },
    skinPrices: { 30: 300, 40: 300, 45: 300, 60: 300 }
  },
  na: {
    currency: "usd",
    currencyCode: "$",
    prices: { 30: 1099, 40: 1599, 45: 2099, 60: 3999 },
    originalPrices: { 30: 1599, 40: 2099, 45: 3099, 60: 4999 },
    skinPrices: { 30: 300, 40: 300, 45: 300, 60: 300 }
  },
  oce: {
    currency: "usd",
    currencyCode: "$",
    prices: { 30: 1099, 40: 1599, 45: 2099, 60: 3999 },
    originalPrices: { 30: 1599, 40: 2099, 45: 3099, 60: 4999 },
    skinPrices: { 30: 300, 40: 300, 45: 300, 60: 300 }
  }
} as const;

export const accountSubscriptionPrices: Record<"base" | "na" | "oce", Prices> =
  {
    base: {
      currency: "eur",
      currencyCode: "€",
      prices: { 30: 1999, 40: 2499, 45: 3799, 60: 6199 },
      originalPrices: { 30: 6500, 40: 7500, 45: 10500, 60: 15000 },
      skinPrices: { 30: 600, 40: 600, 45: 600, 60: 600 }
    },
    na: {
      currency: "usd",
      currencyCode: "$",
      prices: { 30: 2199, 40: 3299, 45: 4199, 60: 7999 },
      originalPrices: { 30: 6500, 40: 7500, 45: 10500, 60: 15000 },
      skinPrices: { 30: 600, 40: 600, 45: 600, 60: 600 }
    },
    oce: {
      currency: "usd",
      currencyCode: "$",
      prices: { 30: 2199, 40: 3299, 45: 4199, 60: 7999 },
      originalPrices: { 30: 6500, 40: 7500, 45: 10500, 60: 15000 },
      skinPrices: { 30: 600, 40: 600, 45: 600, 60: 600 }
    }
  };

export const getServerPrices = (
  server: Region,
  isSubscription?: boolean
): Prices => {
  let prices: Prices = isSubscription
    ? accountSubscriptionPrices["base"]
    : accountProductPrices["base"];
  if (server === "na" || server === "oce")
    prices = isSubscription
      ? accountSubscriptionPrices[server]
      : accountProductPrices[server];
  return prices;
};

export const accountsPerSubscription = 5;
